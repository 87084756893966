<template>
  <div class="redUnion">
    <v-list
      :finished="finished"
      @onLoad="onLoad"
      ref="list"
      :finishedText="finishedText"
    >
      <div
        class="list-content"
        v-for="(item, index) in listData"
        :key="index"
        @click="toRouteTitle(item)"
      >
        <div class="item-l"><img :src="item.picture" alt="" /></div>
        <div class="item-r">
          <div class="title">{{ item.allianceName || "" }}</div>
          <div class="h3">负责人：{{ item.contact || "" }}</div>
          <div class="h4">联系电话：{{ item.contactPhone || "" }}</div>
        </div>
        <div class="detail">
          <div class="detail-l">详情</div>
          <div class="detail-r"><img src="./img/arrow.png" alt="" /></div>
        </div>
      </div>
    </v-list>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { getCpbAllianceInfoPageURL } from "./api.js";
import { getHashParam } from "@/utils/utils.js";
import { gloabalCount, toRegister } from "@/utils/common";
var moment = require("moment");
export default {
  name: "redUnion",
  data() {
    return {
      finishedText: "没有更多了",
      finished: false,
      commonOperaParams: {
        showMy: false,
        isCommonOpera: false,
      },
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
      listData: [],
      bannerList: {},
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {},
  mounted() {},
  methods: {
    onLoad() {
      this.getPartyList();
    },
    toRouteTitle(item) {
      let params = {
        id: item.id,
      };
      this.$router.push({
        name: "redUnionDetail",
        query: {
          id: item.id,
        },
      });
    },
    async getPartyList() {
      let res = await this.$axios.get(getCpbAllianceInfoPageURL, {
        params: {
          tenantId: this.tenantId,
          fromFlag: 1,
          allianceName: "",
          contact: "",
          status: "",
          curPage: this.requestData.curPage++,
          pageSize: this.requestData.pageSize,
        },
      });
      if (res.code === 200) {
        if (res.data.pages <= res.data.current) {
          this.finished = true;
        } else {
          this.$refs.list._data.loading = false;
        }
        this.finishedText = "没有更多了";
        if (res.data.records.length === 0) {
          this.listData = [];
          this.finishedText = "";
          return;
        }
        res.data.records.forEach((item) => {
          // 如果请求数据不重复，则添加进listData
          this.listData.push(item);
        });
      } else {
      }
    },
  },
};
</script>

<style lang="less" scoped>
.redUnion {
  min-height: 100vh;
  padding: 20px 40px;
  box-sizing: border-box;
  .list-content {
    display: flex;
    justify-items: center;
    height: 248px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
    border-radius: 17px;
    margin-bottom: 20px;
    padding: 32px 16px 30px 18px;
    position: relative;
    .item-l {
      width: 248px;
      height: 186px;
      flex-shrink: 0;
      margin-right: 18px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 17px;
      }
    }
    .item-r {
      flex: 1;
      .title {
        width: 284px;
        font-weight: 700;
        font-size: 35px;
        color: #1a1c34;
        line-height: 48px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .h3 {
        font-weight: 400;
        font-size: 26px;
        color: rgba(0, 0, 0, 0.5);
        line-height: 36px;
        margin: 30px 0;
      }
      .h4 {
        font-weight: 400;
        font-size: 26px;
        color: rgba(0, 0, 0, 0.5);
        line-height: 36px;
      }
    }
    .detail {
      width: 82px;
      position: absolute;
      top: 42px;
      right: 0;
      display: flex;
      align-items: center;
      .detail-l {
        font-weight: 500;
        font-size: 24px;
        color: #fb8133;
        line-height: 34px;
      }
      .detail-r {
        width: 28px;
        height: 28px;
        font-size: 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
